.min-vh-75 {
    min-height: 75vh;
}

.vh-75 {
    height: 75vh;
}

.fade.modal-body.newBackdrop.show{
    opacity: 0.1;
   }